
import { reactLocalStorage } from 'reactjs-localstorage';
export const accessToken = () => {
    try {
        const token = reactLocalStorage.get("access_token", false)
        if (token) {
            return {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }

        } else {

        }
    } catch (error) { }
};

