import { getUser } from "api/apis/user";
import { Dispatch } from "redux";
import { USER_DATA_FAIL, USER_DATA_REQ, USER_DATA_SUCC } from "./Type";



export const getSingleUser = (callback = (data:any) => { }) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: USER_DATA_REQ })
        const res = await getUser()
        callback(res.data.data)
        dispatch({
            type: USER_DATA_SUCC,
            payload: res.data.data
        })
    } catch (error: any) {
        dispatch({
            type: USER_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }



}


export const clearUser = () => async (dispatch: Dispatch) => {
    try {



        dispatch({
            type: USER_DATA_SUCC,
            payload: null
        })
    } catch (error: any) {

    }



}