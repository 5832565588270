
import axios from "axios"
import { accessToken } from "utils/token"
export const getUser = async () => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/me`, accessToken())


}

export const getUserUpdate = async (  data: any) => {

    return await axios.put(`${process.env.REACT_APP_API_URL}/v1/user`, data, accessToken())


}
export const getPasswordResetLink = async (  data: any) => {

    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/forgot_password`, data, accessToken())


}
export const changePassword = async (  data: any) => {

    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/reset_password`, data )


}
export const plans = async ( ) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/plan`, accessToken() )


}
export const plansHistory = async ( ) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/plan/my/subscriptions`, accessToken() )
}

export const paymentMethodStatus = async ( ) => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/configure_payment`, accessToken() )


}
export const paymentStripeUrl = async () => {

    return await axios.get(`${process.env.REACT_APP_API_URL}/v1/plan/manage_subscription`, accessToken() )


}


export const paymentMethodConfigure = async ( ) => {

    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/configure_payment`,{}, accessToken() )


}



export const InitshateSubscription = async (data:any) => {

    return await axios.post(`${process.env.REACT_APP_API_URL}/v1/plan/initiate_subscription`,data, accessToken() )


}
 
export const cancelPlan = async ( ) => {

    return await axios.put(`${process.env.REACT_APP_API_URL}/v1/plan/my/cancel`,{}, accessToken() )


}
