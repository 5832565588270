/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';
import { Toaster } from 'react-hot-toast';
import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { Login } from './pages/Login/Loadable';
import ChnagePassword from './components/Login/ChnagePassword';
import ForgotPs from './components/Login/ForgotPs';
import { SignUp } from './pages/signUp/Loadable';
import { ProfileSetup } from './pages/profileSetup/Loadable';
import PaymetScreen from './components/ProfileSetup/PaymetScreen';
import { AdminHome } from './pages/Admin/HomePage/Loadable';
import AdminLogin from './adminComps/Login';
import { Customer } from './pages/Coustomer/Loadable';
import CartCustomer from './pages/Coustomer/cart';
import Profile from './pages/Coustomer/profile';
import { Landing } from './pages/LandingPage/Loadable';
import { AllBlog } from './pages/LandingPage/AllBlog/Loadable';
import { SingleBlog } from './pages/LandingPage/SingleBlogPage/Loadable';
import { Verify } from './pages/verify';
import PrivateRoute from 'utils/routeComponents/PrivateRoute';
import LoginRedirect from 'utils/routeComponents/LoginRedirect';
import PaymentSuccess from './components/checkoutForm/PaymentSuccess';
import SubscriptionSuccess from './components/checkoutForm/SubscriptionSuccess';
import PrivateRouteAdmin from 'utils/routeComponents/PrivateRouteAdmin';
import PaymentConfigureSuccess from './components/checkoutForm/PaymentConfigureSuccess';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s"
        defaultTitle="Grab a snak"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Grab a snak" />
      </Helmet>

      <Routes>
        {/* <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} /> */}
        {/* <Route path="/landing" element={<Landing />} />
        <Route path="/landing/all-blog" element={<AllBlog />} />
        <Route path="/landing/blog/:slug" element={<SingleBlog />} />
        <Route path="/login" element={<LoginRedirect><Login /> </LoginRedirect>} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/changepassword" element={<ChnagePassword />} />
        <Route path="/forgotpassword" element={<ForgotPs />} />
        <Route path="/profilesetup" element={<ProfileSetup />} />
        <Route path="/payment" element={<PaymetScreen />} /> */}
        <Route path="/" element={<PrivateRouteAdmin><AdminHome /></PrivateRouteAdmin>} />
        <Route path="/login" element={<AdminLogin />} />
        {/* <Route path="/payment_callback" element={<PaymentSuccess />} />
        <Route path="/subscription" element={<SubscriptionSuccess />} />
        <Route path="/payment_method_return" element={<PaymentConfigureSuccess />} />
        <Route path="/:slug" element={<Customer />} />
        <Route path="/:slug/cart" element={<CartCustomer />} />
        <Route path="/:slug/profile" element={<Profile />} />

        <Route path="/:slug/not_Found" element={<NotFoundPage />} />
        <Route   element={<NotFoundPage />} /> */}
      </Routes>
      <GlobalStyle />
      <Toaster toastOptions={{
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff',
        }
      }} />
    </BrowserRouter>
  );
}

