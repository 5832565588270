import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";



function PrivateRouteAdmin({ children }) {
    let user = useSelector((state: any) => {
        return state.user.data;
    });



     
    return user?.role==="ADMIN" ? children : <Navigate to="/login" />;
}



export default PrivateRouteAdmin
