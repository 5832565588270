import { login } from 'api/apis/login'
import { useFormik } from 'formik'
import { Eye, EyeSlash, UserPlus } from 'phosphor-react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { reactLocalStorage } from 'reactjs-localstorage'
import { GetPaymentStatus } from 'redux/actions/user/PaymentStatus'
import { getSingleUser } from 'redux/actions/user/UserAction'
import styled from 'styled-components'
import { Input } from 'styles/Input'

const ImageBgDiv = styled.div`
    background-image: url("/images/adminLoginBg.svg")
`


const AdminLogin = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [showPass, setShowPass] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    // validate,
    onSubmit: async (values) => {
      toast.loading("Logging In")

      try {
        const res = await login(values)

        reactLocalStorage.set("access_token", res.data.data.token)
        dispatch(getSingleUser((data) => {

          if (data?.role === "ADMIN"){
            console.log(data)
          navigate('/')
          }else {
            toast.dismiss()
            toast.error(" login failed")
          }


        }))

      } catch (error: any) {
        toast.dismiss()
        console.log(error?.response)
        toast.error(error?.response?.data?.message)

      }

    }


  })
  return (
    <>
      <Helmet>
        <title>Grab A Snak-Admin Login </title>
        <meta name="description" content="Grab A Snak-Admin Login Page" />
      </Helmet>
      <ImageBgDiv className='fixed flex justify-center items-center w-[100vw] h-[100vh] bg-theme-green'>
        <div className='w-[25%] bg-white rounded-[15px] px-6 py-8'>
          <img src="/images/GAS.png" alt="" />
          <p className='my-10 flex gap-2 '><UserPlus className='text-[#EDB037]' size={26} weight="bold" /> <span className='text-theme-black text-[30px] font-bold'>Login</span></p>

          <label className=' text-theme-black pl-1' htmlFor="email">Email</label>
          {/* <input  placeholder='jhonsmith@example.com' className='w-full focus:outline-[#103734] my-2 mb-6 border rounded-full px-4 py-2' type="email" /> */}
          <Input name={"email"} onChange={formik.handleChange} value={formik.values.email} placeholder='jhonsmith@example.com' type="email" />


          <label className=' text-theme-black pl-1' htmlFor="email">Password</label>
          <div className='relative  mb-5'>
            <Input name={"password"} onChange={formik.handleChange} value={formik.values.password} type={showPass ? "text" : 'password'} />
            {showPass ?
              <Eye onClick={() => setShowPass(!showPass)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" /> :
              <EyeSlash onClick={() => setShowPass(!showPass)} className='absolute top-1/2 -translate-y-1/2 right-4 text-theme-black' size={16} weight="bold" />
            }
          </div>

          <button type='button' onClick={() => formik.handleSubmit()} className='w-full py-2 rounded-full bg-theme-green text-white mt-4 mb-6'>Login</button>

        </div>
      </ImageBgDiv>
    </>
  )
}

export default AdminLogin